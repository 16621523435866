<template>
  <ModalDrawer
    visible
    :title="t('payoutDetails')"
    hide-footer
    @hide="hide"
  >
    <BookingPriceBreakdownReceipt
      :financial="financial"
      :delivery-distance="deliveryDistance"
      :hide-receipt-footer="hideReceiptFooter"
    />
  </ModalDrawer>
</template>

<script setup lang="ts">
import type { FinancialCommon } from '~/types'

defineProps<{
  financial: FinancialCommon
  deliveryDistance?: number
  hideReceiptFooter?: boolean
}>()

const emit = defineEmits<{
  hide: []
}>()

const { t } = useI18n()

function hide() {
  emit('hide')
}
</script>

<i18n lang="json">
  {
    "en": {
      "payoutDetails": "Payout details"
    },
    "fr": {
      "payoutDetails": "Détails du paiement"
    }
  }
</i18n>
